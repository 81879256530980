define("discourse/plugins/discourse-whos-online/discourse/services/whos-online", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/models/site"], function (_exports, _object, _computed, _service, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WhosOnlineService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "users", [(0, _computed.readOnly)("channel.users")]))();
    #users = (() => (dt7948.i(this, "users"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "count", [(0, _computed.readOnly)("channel.count")]))();
    #count = (() => (dt7948.i(this, "count"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "countOnly", [(0, _computed.readOnly)("channel.countOnly")]))();
    #countOnly = (() => (dt7948.i(this, "countOnly"), void 0))();
    init() {
      super.init(...arguments);
      this.set("channel", this.presence.getChannel("/whos-online/online"));
      if (this.enabled) {
        this.channel.subscribe(_site.default.currentProp("whos_online_state"));
      }
      this.addObserver("users.[]", this, this._usersChanged);
    }
    _usersChanged() {
      const currentUserIds = new Set(this.users?.map(u => u.id) || []);
      const prevUserIds = this._prevUserIds || new Set([]);
      const enteredUsers = [...currentUserIds].filter(x => !prevUserIds.has(x));
      const leftUsers = [...prevUserIds].filter(x => !currentUserIds.has(x));
      const changedUsers = [...enteredUsers, ...leftUsers];
      if (changedUsers.length > 0) {
        this.appEvents.trigger("whosonline:changed", changedUsers);
      }
    }
    get enabled() {
      const anonAndLoginRequired = !this.currentUser && this.siteSettings.login_required;
      if (anonAndLoginRequired) {
        return false;
      }
      return this.siteSettings.whos_online_display_public || this.currentUser?.trust_level >= this.siteSettings.whos_online_display_min_trust_level;
    }
    static #_6 = (() => dt7948.n(this.prototype, "enabled", [_object.computed]))();
    isUserOnline(id) {
      return !!this.channel?.users?.findBy("id", id);
    }
  }
  _exports.default = WhosOnlineService;
});