define("discourse/plugins/discourse-whos-online/discourse/api-initializers/intitialize-whos-online-indicators", ["exports", "@ember/service", "discourse/lib/api", "discourse/lib/decorators"], function (_exports, _service, _api, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "whos-online";
  var _default = _exports.default = (0, _api.apiInitializer)("1.39.0", api => {
    const siteSettings = api.container.lookup("service:site-settings");

    // pre-initialize the service so that it can start listening to the presence channel
    // and avoid triggering "... was previously used in the same computation" errors
    // while subscribing
    api.container.lookup("service:whos-online");
    const indicatorType = siteSettings.whos_online_avatar_indicator;
    if (indicatorType === "none") {
      return;
    }
    if (!(siteSettings.whos_online_display_public || api.getCurrentUser()?.trust_level >= siteSettings.whos_online_display_min_trust_level)) {
      return;
    }
    document.documentElement.classList.add(`whos-online-${indicatorType}`);
    api.modifyClass("component:user-card-contents", dt7948.p({
      pluginId: PLUGIN_ID,
      whosOnline: (0, _service.service)(),
      classNameBindings: ["isOnline:user-online"],
      isOnline(user) {
        return user && this.whosOnline.isUserOnline(user.id);
      }
    }, [["method", "isOnline", [(0, _decorators.default)("user", "whosOnline.users.[]")]]]));
    api.modifyClass("route:user", dt7948.p({
      pluginId: PLUGIN_ID,
      whosOnline: (0, _service.service)(),
      async afterModel(model) {
        const superVal = await this._super(...arguments);
        this.set("whosOnlineUserId", model.id);
        this.updateBodyClass();
        return superVal;
      },
      isOnline(userId) {
        return userId && this.whosOnline.isUserOnline(userId);
      },
      updateBodyClass() {
        if (this.isOnline) {
          document.body.classList.add("user-page-online");
        } else {
          document.body.classList.remove("user-page-online");
        }
      },
      deactivate() {
        this._super();
        document.body.classList.remove("user-page-online");
      }
    }, [["method", "isOnline", [(0, _decorators.default)("whosOnlineUserId", "whosOnline.users.[]")]], ["method", "updateBodyClass", [(0, _decorators.observes)("isOnline")]]]));
    if (siteSettings.whos_online_avatar_indicator_topic_lists) {
      const addLastPosterOnlineClassNameTransformer = _ref => {
        let {
          value: additionalClasses,
          context: {
            topic
          }
        } = _ref;
        if (topic) {
          const whosOnline = api.container.lookup("service:whos-online");
          const {
            lastPoster,
            lastPosterUser
          } = topic;
          if (whosOnline.isUserOnline(lastPoster?.id || lastPosterUser?.id)) {
            additionalClasses.push("last-poster-online");
          }
        }
        return additionalClasses;
      };
      api.registerValueTransformer("latest-topic-list-item-class", addLastPosterOnlineClassNameTransformer);
      api.registerValueTransformer("topic-list-item-class", addLastPosterOnlineClassNameTransformer);
    }
    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,
      didInsertElement() {
        this._super();
        this.appEvents.on("whosonline:changed", this, this._whosOnlineCallback);
      },
      willDestroyElement() {
        this.appEvents.off("whosonline:changed", this, this._whosOnlineCallback);
      },
      _whosOnlineCallback(changedUserIds) {
        changedUserIds.forEach(id => {
          let postIds = this.attrs.posts.value.filter(_ref2 => {
            let {
              user_id
            } = _ref2;
            return user_id === id;
          }).map(post => post.id);
          postIds.forEach(postId => {
            this.dirtyKeys.keyDirty(`post-${postId}`);
            this.dirtyKeys.keyDirty(`post-${postId}-avatar-${id}`, {
              onRefresh: "updateOnline"
            });
          });
        });
        this.queueRerender();
      }
    });
    api.reopenWidget("post-avatar", {
      buildKey: attrs => `post-${attrs.id}-avatar-${attrs.user_id}`,
      isUserOnline(userId) {
        return this.register.lookup("service:whos-online").isUserOnline(userId);
      },
      defaultState(attrs) {
        return {
          online: this.isUserOnline(attrs.user_id)
        };
      },
      updateOnline() {
        this.state.online = this.isUserOnline(this.attrs.user_id);
      },
      buildClasses(attrs, state) {
        if (state.online) {
          return "user-online";
        }
        return [];
      }
    });
  });
});