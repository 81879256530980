define("discourse/plugins/discourse-whos-online/discourse/components/whos-online", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/template-factory"], function (_exports, _component, _object, _computed, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div id="whos-online" class={{if this.isLong "collapsed"}}>
      <span
        title={{i18n
          "whos_online.tooltip"
          count=this.siteSettings.whos_online_active_timeago
        }}
      >
        {{#if this.hasUsers}}
          {{i18n "whos_online.title" count=this.count}}
        {{else if this.hasCount}}
          {{i18n "whos_online.count_only" count=this.count}}
        {{else}}
          {{i18n "whos_online.no_users"}}
        {{/if}}
      </span>
      {{#if this.hasUsers}}
        {{#each this.users as |user|}}
          {{whos-online-avatar user=user}}
        {{/each}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "mWlqIOv4",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,1,\"whos-online\"],[15,0,[52,[30,0,[\"isLong\"]],\"collapsed\"]],[12],[1,\"\\n    \"],[10,1],[15,\"title\",[28,[37,3],[\"whos_online.tooltip\"],[[\"count\"],[[30,0,[\"siteSettings\",\"whos_online_active_timeago\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasUsers\"]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.title\"],[[\"count\"],[[30,0,[\"count\"]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasCount\"]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.count_only\"],[[\"count\"],[[30,0,[\"count\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.no_users\"],null]],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasUsers\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,6],null,[[\"user\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"div\",\"span\",\"i18n\",\"each\",\"-track-array\",\"whos-online-avatar\"]]",
    "moduleName": "discourse/plugins/discourse-whos-online/discourse/components/whos-online.hbs",
    "isStrictMode": false
  });
  class WhosOnline extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "whosOnline", [_service.service]))();
    #whosOnline = (() => (dt7948.i(this, "whosOnline"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "count", [(0, _computed.readOnly)("whosOnline.count")]))();
    #count = (() => (dt7948.i(this, "count"), void 0))();
    get users() {
      return this.whosOnline.users?.slice(0, this.siteSettings.whos_online_maximum_display);
    }
    static #_3 = (() => dt7948.n(this.prototype, "users", [(0, _object.computed)("whosOnline.users.[]")]))();
    get hasUsers() {
      return this.users?.length >= this.siteSettings.whos_online_minimum_display;
    }
    static #_4 = (() => dt7948.n(this.prototype, "hasUsers", [(0, _object.computed)("users", "users.length")]))();
    get hasCount() {
      return this.count >= this.siteSettings.whos_online_minimum_display;
    }
    static #_5 = (() => dt7948.n(this.prototype, "hasCount", [(0, _object.computed)("count")]))();
    get isLong() {
      return this.count >= this.siteSettings.whos_online_collapse_threshold;
    }
    static #_6 = (() => dt7948.n(this.prototype, "isLong", [(0, _object.computed)("count")]))();
    get shouldDisplay() {
      if (this.count < this.siteSettings.whos_online_minimum_display && this.siteSettings.whos_online_hide_below_minimum_display) {
        return false;
      }
      return this.whosOnline.enabled;
    }
    static #_7 = (() => dt7948.n(this.prototype, "shouldDisplay", [(0, _object.computed)("count")]))();
  }
  _exports.default = WhosOnline;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WhosOnline);
});