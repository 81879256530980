define("discourse/plugins/discourse-whos-online/discourse/components/whos-online-avatar", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{avatar
    this.user
    avatarTemplatePath="avatar_template"
    title=this.user.username
    imageSize="small"
  }}
  */
  {
    "id": "E+auEU7x",
    "block": "[[[1,[28,[35,0],[[30,0,[\"user\"]]],[[\"avatarTemplatePath\",\"title\",\"imageSize\"],[\"avatar_template\",[30,0,[\"user\",\"username\"]],\"small\"]]]]],[],false,[\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-whos-online/discourse/components/whos-online-avatar.hbs",
    "isStrictMode": false
  });
  class WhosOnlineAvatar extends _component.default {
    tagName = "a";
    attributeBindings = ["user.username:data-user-card", "user.path:href"];
  }
  _exports.default = WhosOnlineAvatar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WhosOnlineAvatar);
});